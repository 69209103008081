import { Injectable } from "@angular/core";
import { Recipes } from "../models";
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { style } from "@angular/animations";

@Injectable({
    providedIn: "root",
})
export class PDFService {

    constructor() {
        (window as any).pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }
    generateUOMCalculations(crop: string, recipeDetails: Recipes, dsIngredients: any[], dsUnit: any[], dsMixInstructionsText: any[]) {
        let dd: any = {
            content: [],
            styles: {
                header: {
                    fontSize: 10
                },
                tableExample: {
                    fontSize: 10
                },
                tableHeader: {
                    bold: true,
                    fontSize: 10,
                }
            }
        }

        const dsUnitTableData = [];
        const dsUnitKeys = Object.keys(dsUnit[0]);
        const dsUnitValues = Object.values(dsUnit[0]);
        dsUnitTableData.push(dsUnitKeys);
        dsUnitTableData.push(dsUnitValues);

        const dsIngTableData = [];
        let widths = [];
        if (dsIngredients.length > 0) {
            const dsIngKeys = Object.keys(dsIngredients[0]);
            widths = Array(dsIngKeys.length).fill(45);
            dsIngTableData.push(dsIngKeys);
            dsIngredients.forEach(x => {
                dsIngTableData.push(Object.values(x));
            })
        }


        dd.content.push({
            text: `Crop: ${crop} `,
            style: 'header'
        });

        dd.content.push({
            text: [
                { text: `Recipe Name: ${recipeDetails.name}           `, style: 'header' },
                { text: `PRISM Code: ${recipeDetails.prismCode}`, style: 'header' },
            ]
        });

        dd.content.push({
            text: `Recipe Description: ${recipeDetails.description} `,
            style: 'header'
        });

        dd.content.push({
            style: 'tableExample',
            table: {
                heights: 20,
                body: dsUnitTableData
            },
            margin: [0, 10, 0, 0]
        });

        if (dsIngTableData.length > 0) {
            dd.content.push({
                style: 'tableExample',
                table: {
                    headerRows: 1,
                    widths: widths,
                    heights: 20,
                    body: dsIngTableData
                },
                margin: [0, 10, 0, 0]
            });
        } else {
            dd.content.push({
                text: `No ingredients found`,
                style: 'header',
                margin: [0, 10, 0, 0]
            });
        }

        if (recipeDetails.mixInstructions.length > 0) {
            dsMixInstructionsText.forEach(x => {
                dd.content.push({
                    margin: `${x.type === 'Text' ? [0, 10, 0, 0] : [0, 0, 0, 0]}`.split(',').map(x => parseInt(x)),
                    text: `${(x.mixingOrder !== '' && x.mixingOrder !== null) ? x.mixingOrder + ' ' : ''}${x.text}`,
                    style: 'header'
                });
            });
        } else {
            dd.content.push({
                text: `No mix instructions found`,
                style: 'header'
            });
        }



        pdfMake.createPdf(dd).download(recipeDetails.name + " UOM calculations");
    }

}