import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { ICellEditorParams } from 'ag-grid-community';
import { Dropdown } from 'primeng/dropdown';
import { CropsPO, MSRecipeInfoPO, StationsPO } from 'src/app/models/manage-standardpo';
import { AppConstants } from '../../constants';

@Component({
  selector: 'app-custom-cell-editor',
  templateUrl: './custom-cell-editor.component.html',
  styleUrl: './custom-cell-editor.component.scss'
})
export class CustomCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any;
  options: any[] = [];
  @ViewChild('myDropdown', { static: false }) myDropdown: Dropdown;

  ngAfterViewInit(): void {
    this.myDropdown.show();
  }

  agInit(params: any): void {
    this.params = params;
    this.value = this.params.value;
    if (this.params.dataSourceType) {
      if (this.params.dataSourceType === AppConstants.Recipe) {
        this.params.values.forEach((val: MSRecipeInfoPO) => this.options.push({ name: val.recipeName, value: val.recipeName, inactive: val.disabled }));
      } else if (this.params.dataSourceType === AppConstants.Crop) {
        this.params.values.forEach((val: CropsPO) => this.options.push({ name: val.description, value: val.description, inactive: val.disabled }));
      } else if (this.params.dataSourceType === AppConstants.Country) {
        this.params.values.forEach((val: StationsPO) => this.options.push({ name: val.country, value: val.country, inactive: val.disabled }));
      } else if (this.params.dataSourceType === AppConstants.Status) {
        this.params.values.forEach((val: any) => this.options.push({ name: val.status, value: val.status, inactive: val.disabled }));
      } else if (this.params.dataSourceType === AppConstants.LabelAs) {
        this.params.values.forEach((val: any) => this.options.push({ name: val.labelAs, value: val.labelAs, inactive: val.disabled }));
      } else if (this.params.dataSourceType === AppConstants.FactsId) {
        this.params.values.forEach((val: any) => this.options.push({ name: val.factsId, value: val.factsId, inactive: val.disabled }));
      }
    }
  }

  getValue(): any {
    return this.value;
  }

  onValueChange(event: any): void {
    this.value = event.value;
    this.params.api.stopEditing(); // Stop editing mode in ag-Grid
  }

}
