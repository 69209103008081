import { Injectable } from '@angular/core';
import { Volume, VolumeUnits } from 'unitsnet-js';
import { AppConstants } from '../shared/constants';

@Injectable({
    providedIn: "root",
})
export class UOMConversionService {

    convertVolume(toUnit: any, country: string) {
        const fromVolumeUnit = country === AppConstants.UnitedKingdom ? VolumeUnits.ImperialOunces : VolumeUnits.UsOunces;
        if (toUnit === 'fl oz') {
            if (country === AppConstants.UnitedKingdom) {
                toUnit = 'ImperialOunces';
            } else {
                toUnit = 'UsOunces';
            }
        }
        const toVolumeUnit = VolumeUnits[toUnit as keyof typeof VolumeUnits];
        if (!fromVolumeUnit || !toVolumeUnit) {
            throw new Error('Invalid unit provided');
        }

        const volume = new Volume(1, fromVolumeUnit);
        return volume.toDto(toVolumeUnit).value;
    }
}