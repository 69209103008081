import { DecimalPipe } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { CellEditingStoppedEvent, ColDef, StatusPanelDef } from 'ag-grid-community';
import { DialogService, DynamicDialogComponent, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Recipes } from 'src/app/models';
import { AgEventhandlerService } from 'src/app/services/ag-eventhandler.service';
import { UOMConversionService } from 'src/app/services/uom-conversion.service';
import { StaticFields } from '../../staticFields';
import { Metric, Volume } from '../../constants';
import { PDFService } from 'src/app/services';


@Component({
  selector: 'app-recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrl: './recipe-details.component.scss'
})
export class RecipeDetailsComponent implements OnInit {

  recipeDetails: Recipes;
  crop: string;
  instance: DynamicDialogComponent | undefined;
  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    resizable: false,
    filter: false,
    sortable: false,
    flex: 1
  };
  ingColumnDefs: ColDef[] = [];
  dsUnit: any[] = [];
  dsIngredients: any[] = [];
  amountOfSeedUOM: string = Metric.Lbs;
  totalVolumeUOM: string = Volume.mL;
  amountOfSeedUOMField: string = '';
  totalVolumeField: string = '';
  dsMixInstructionsText: any[] = [];
  gridUnitOptions = {
    columnDefs: this.columnDefs,
    defaultColDef: this.defaultColDef,
    suppressContextMenu: true,
    rowData: this.dsUnit,
  };
  gridUnitParams!: any;

  gridIngOptions = {
    suppressColumnVirtualisation: true,
    columnDefs: this.ingColumnDefs,
    rowData: this.dsIngredients,
  }
  gridIngParams!: any;
  statusBarIng!: {
    statusPanels: StatusPanelDef[];
  }

  amountOfSeedDictionary: { [key: string]: string } = { 'Lbs': 'Pounds', 'Kg': 'Kilograms' };
  volumeDictionary: { [key: string]: string } = { 'mL': 'Milliliters', 'L': 'Liters', 'gallon': 'gallon', 'fl oz': 'fl oz' };

  chooseUOMRB: string = Metric.Lbs;
  countValue: number;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    const gridElement = document.querySelector('.ag-root');
    if (gridElement && !gridElement.contains(event.target as Node) && this.gridUnitParams) {
      this.gridUnitParams.api.stopEditing();
    }
  }

  constructor(private ref: DynamicDialogRef, private dialogService: DialogService,
    private agSvc: AgEventhandlerService, private decimalPipe: DecimalPipe,
    private uomSvc: UOMConversionService, private pdfSvc: PDFService
  ) {
    this.instance = this.dialogService.getInstance(this.ref);
  }
  ngOnInit(): void {
    this.statusBarIng = this.agSvc.getGridStatusBar();
    if (this.instance && this.instance.data) {
      this.crop = this.instance.data['crop'];
      this.recipeDetails = this.instance.data['recipeDetails'];
      if (this.recipeDetails.mixInstructionText !== null) {
        this.recipeDetails.mixInstructionText.split('\n').filter(x => x).forEach(item => {
          let isIngFound = this.recipeDetails.mixInstructions.find(x => x.instruction.toLowerCase() === item.toLowerCase());
          if (isIngFound) {
            this.dsMixInstructionsText.push({ mixingOrder: isIngFound.mixingOrder + ')', text: item, amount: '', type: 'Ingredient' });
          } else {
            this.dsMixInstructionsText.push({ mixingOrder: '', text: item, amount: '', type: 'Text' });
          }
        });
      }
    }
  }

  createDataUnit() {
    this.columnDefs = [];
    this.dsUnit = [];
    this.amountOfSeedUOMField = `${this.amountOfSeedDictionary[this.amountOfSeedUOM] ? this.amountOfSeedDictionary[this.amountOfSeedUOM] : this.amountOfSeedUOM} of seed to be treated`;
    this.totalVolumeField = `Total ${this.totalVolumeUOM} to mix`;
    let newField: any = {};
    newField[this.amountOfSeedUOMField] = null;
    newField[this.totalVolumeField] = null;
    this.dsUnit.push(newField);
    const keys = Object.keys(this.dsUnit[0]);
    keys.forEach(key => this.columnDefs.push({ field: key, cellDataType: 'number', headerName: key, suppressHeaderMenuButton: true }));
    this.gridUnitParams.api.setGridOption("columnDefs", this.columnDefs);
    this.gridUnitParams.api.setGridOption("rowData", this.dsUnit);
  }

  createIngredientData() {
    this.ingColumnDefs = [];
    this.dsIngredients = [];
    this.recipeDetails.recipeIngredients.forEach((x, i) => {
      let recommendRate: any = x.targetRate_g_per_l !== null ? this.calculateRecommendedRate(x.targetRate_g_per_l) : null;
      let newIngreident: any = {};
      newIngreident['Ingredient Name'] = x.ingredient.name;
      newIngreident['Active Ingredient(s)'] = x.ingredient.activeIngredients;
      newIngreident['FACTS ID'] = x.ingredient.externalId;
      newIngreident['Recommend Rate'] = recommendRate !== null ? recommendRate + " fl oz per cwt" : recommendRate;
      newIngreident['Chemical Rate: fl oz per 100 ' + (this.amountOfSeedUOM == Metric.Kg ? this.amountOfSeedUOM + 's' : this.amountOfSeedUOM)] = recommendRate;
      newIngreident['Chemical Rate: ' + this.totalVolumeUOM + ' per ' + (this.amountOfSeedUOM === Metric.Lbs ? 'lb' : this.amountOfSeedUOM) + " of seed"] = recommendRate !== null ? this.calculateChemicalRateForML(recommendRate) : null;
      newIngreident['Applicate Rate (' + this.totalVolumeUOM + ' per ' + (this.amountOfSeedUOM === Metric.Lbs ? 'pound ' : this.amountOfSeedUOM) + ' seed)'] = i === 0 ? this.recipeDetails.csatApplicationRate : null;
      newIngreident['Total ' + this.totalVolumeUOM + ' you want to mix'] = null;
      newIngreident['Water (' + this.totalVolumeUOM + ')'] = null;
      newIngreident['Chemical (' + this.totalVolumeUOM + ')'] = null;
      this.dsIngredients.push(newIngreident);
    });
    if (this.dsIngredients.length > 0) {
      const keys = Object.keys(this.dsIngredients[0]);
      keys.forEach(key => {
        this.ingColumnDefs.push({ field: key, headerName: key, filter: true });
      });
      this.gridIngParams.api.setGridOption("columnDefs", this.ingColumnDefs);
      this.gridIngParams.api.setGridOption("rowData", this.dsIngredients);
    }
  }

  calculateIngredientApplicationRate(amountValue: any, totalVolumeValue: any) {
    if (this.dsIngredients.length > 0) {
      let sum = 0;
      this.dsIngredients.forEach((x, i) => {
        if (i === 0) {
          x['Total ' + this.totalVolumeUOM + ' you want to mix'] = totalVolumeValue;
        }
        x['Chemical (' + this.totalVolumeUOM + ')'] = amountValue * x['Chemical Rate: ' + this.totalVolumeUOM + ' per ' + (this.amountOfSeedUOM === Metric.Lbs ? 'lb' : this.amountOfSeedUOM) + " of seed"];
        sum += x['Chemical (' + this.totalVolumeUOM + ')'];
      });
      this.dsIngredients[0]['Water (' + this.totalVolumeUOM + ')'] = totalVolumeValue - sum;
      this.gridIngParams.api.setGridOption("rowData", this.dsIngredients);
    }
  }

  calculateRecommendedRate(recommendedRate: any) {
    let oneGramEqualsOZ = 0.03527396;
    let recommendedRateOZ = recommendedRate * oneGramEqualsOZ;
    let oz = this.uomSvc.convertVolume('fl oz', StaticFields.userInfo.country);
    let floz = recommendedRateOZ * oz;
    let oneLiterEqualsLbs = 2.20462;
    return this.decimalPipe.transform(((floz / oneLiterEqualsLbs) * 100), '1.3-3');
  }

  onUnitGridReady(params: any) {
    this.gridUnitParams = params;
    this.createDataUnit();
  }

  onIngGridReady(params: any) {
    this.gridIngParams = params;
    this.createIngredientData();
  }

  exportPDF() {
    this.pdfSvc.generateUOMCalculations(this.crop, this.recipeDetails, this.dsIngredients, this.dsUnit, this.dsMixInstructionsText);
  }

  onUOMSelect() {
    this.amountOfSeedUOM = this.chooseUOMRB;
    this.createDataUnit();
    if (this.countValue !== null && this.countValue !== undefined) {
      //TO DO Need to convert the count to selected UOM then populate data in first grid(UOM) and second grid(Ingredients)
      if (this.chooseUOMRB === Metric.Lbs) {
        this.dsUnit[0][this.amountOfSeedUOMField] = 100;
        this.dsUnit[0][this.totalVolumeField] = this.recipeDetails.csatApplicationRate !== null ? this.dsUnit[0][this.amountOfSeedUOMField] * this.recipeDetails.csatApplicationRate : null;
      } else {
        this.dsUnit[0][this.amountOfSeedUOMField] = 200;
        this.dsUnit[0][this.totalVolumeField] = this.recipeDetails.csatApplicationRate !== null ? this.dsUnit[0][this.amountOfSeedUOMField] * this.recipeDetails.csatApplicationRate : null;
      }
      this.gridUnitParams.api.setGridOption("rowData", this.dsUnit);
      this.createIngredientData();
      if (this.dsUnit[0][this.amountOfSeedUOMField] !== null && this.dsUnit[0][this.amountOfSeedUOMField] !== '' && this.dsUnit[0][this.totalVolumeField] !== null && this.dsUnit[0][this.totalVolumeField] !== '') {
        this.calculateIngredientApplicationRate(this.dsUnit[0][this.amountOfSeedUOMField], this.dsUnit[0][this.totalVolumeField]);
      }
    }
  }

  changeUOM() {
    if (this.countValue !== null && this.countValue !== undefined) {
      this.amountOfSeedUOM = this.chooseUOMRB;
      this.createDataUnit();
      //TO DO Need to convert the count to selected UOM through SI API then populate data in first grid(UOM) and second grid(Ingredients)
      if (this.chooseUOMRB === Metric.Lbs) {
        this.dsUnit[0][this.amountOfSeedUOMField] = 100;
        this.dsUnit[0][this.totalVolumeField] = this.recipeDetails.csatApplicationRate !== null ? this.dsUnit[0][this.amountOfSeedUOMField] * this.recipeDetails.csatApplicationRate : null;
      } else {
        this.dsUnit[0][this.amountOfSeedUOMField] = 200;
        this.dsUnit[0][this.totalVolumeField] = this.recipeDetails.csatApplicationRate !== null ? this.dsUnit[0][this.amountOfSeedUOMField] * this.recipeDetails.csatApplicationRate : null;
      }
      this.gridUnitParams.api.setGridOption("rowData", this.dsUnit);
      this.createIngredientData();
      if (this.dsUnit[0][this.amountOfSeedUOMField] !== null && this.dsUnit[0][this.amountOfSeedUOMField] !== '' && this.dsUnit[0][this.totalVolumeField] !== null && this.dsUnit[0][this.totalVolumeField] !== '') {
        this.calculateIngredientApplicationRate(this.dsUnit[0][this.amountOfSeedUOMField], this.dsUnit[0][this.totalVolumeField]);
      }
    }
  }

  close() {
    this.ref.close();
  }

  calculateChemicalRateForML(recommendRate: any) {
    let oneOunceToSelctedVolume: any = this.decimalPipe.transform(this.uomSvc.convertVolume(this.volumeDictionary[this.totalVolumeUOM], StaticFields.userInfo.country), '1.3-3');
    let convertOunceToMl = (recommendRate * oneOunceToSelctedVolume) / 100;
    return this.decimalPipe.transform(convertOunceToMl, '1.3-3');
  }

  checkIngredient(item: string) {
    let isIngFound = this.recipeDetails.mixInstructions.find(x => x.instruction.toLowerCase() === item.toLowerCase());

  }

}
