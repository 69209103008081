<div>
    <div class="row">
        <div class="row-reverse">
            <p-button label="Close" (onClick)="close()" />
            <p-button label="Export PDF" (onClick)="exportPDF()" class="mr-2" />
        </div>
    </div>
    <div class="row" *ngIf="recipeDetails">
        <div class="col-8">
            <div class="row">
                <div class="col-auto pt-0 pb-0">
                    Crop:
                </div>
                <div class="col-11 break-word pt-0 pb-0">
                    {{crop}}
                </div>
            </div>
            <div class="row">
                <div class="col-auto pt-0 pb-0">
                    Recipe Name:
                </div>
                <div class="col-3 pt-0 pb-0">
                    {{recipeDetails.name}}
                </div>
                <div class="col-auto pt-0 pb-0">
                    PRISM Code:
                </div>
                <div class="col-auto break-word pt-0 pb-0">
                    {{recipeDetails.prismCode}}
                </div>
            </div>
            <div class="row">
                <div class="col-auto pt-0 pb-0">
                    Recipe Description:
                </div>
                <div class="col-auto break-word pt-0 pb-0">
                    {{recipeDetails.description}}
                </div>
            </div>
        </div>
        <div class="col-4">
            <p class="mb-0">Choose UOM:</p>
            <p-radioButton [(ngModel)]="chooseUOMRB" name="Metric" value="Lbs" (onClick)="onUOMSelect()"
                inputId="metric1" />
            <label for="metric1" class="ml-2 mr-2">
                Weight in Lbs
            </label>
            <p-radioButton [(ngModel)]="chooseUOMRB" name="Metric" value="Kg" (onClick)="onUOMSelect()"
                inputId="metric2" />
            <label for="metric2" class="ml-2">
                Weight in Kg
            </label>
            <div class="row mt-2">
                <label for="count">
                    Calculate weight from count:
                </label>
                <p-inputNumber autocomplete="off" inputId="integeronly" [(ngModel)]="countValue" inputId="count"
                    (onBlur)="changeUOM()" />
            </div>
        </div>
        <div class="row">
            <div class="col-8">
                <ag-grid-angular [gridOptions]="gridUnitOptions" class="ag-theme-alpine uom-grid-ht"
                    (gridReady)="onUnitGridReady($event)"></ag-grid-angular>
            </div>
        </div>
        <div class="row" *ngIf="recipeDetails.recipeIngredients.length > 0; else noIngredients">
            <ag-grid-angular [gridOptions]="gridIngOptions" [animateRows]="true" class="ag-theme-alpine grid-size"
                [animateRows]="true" (gridReady)="onIngGridReady($event)" [statusBar]="statusBarIng"></ag-grid-angular>
        </div>
        <div class="row" *ngIf="recipeDetails.mixInstructions.length > 0; else noMixIns">
            <ng-container *ngFor="let item of dsMixInstructionsText; let i = index">
                <div [ngClass]="item.type === 'Text'? 'mt-2' :''">
                    <span *ngIf="item.mixingOrder">{{item.mixingOrder}}&nbsp;</span>{{item.text}}
                </div>
            </ng-container>
        </div>

        <ng-template #noIngredients>
            <div class="row">
                <p class="mb-0">No ingredients found</p>
            </div>
        </ng-template>

        <ng-template #noMixIns>
            <div class="row">
                <p class="mb-0">No mix instructions found</p>
            </div>
        </ng-template>
    </div>
</div>